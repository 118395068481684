<template>
  <div class="iriets-technology">
    <div class="top-banner">
      <div class="technology-image" :style="{'backgroundImage':`url(${require('@/assets/technology/2/1.png')})`}">
        <div class="wrapper">
          <el-row>
            <el-col :span="12">
              <div class="f50 cfff title">数据治理</div>
              <div class="f20 cfff desc">
                借助以有效的元数据和策略管理为支持的数据目录，激活用于AI和分析的业务就绪数据，实现对敏感数据的发现、标注、管理和隐私保护，为用户提供一站式的自助服务式的数据体验。
              </div>
            </el-col>
          </el-row>

        </div>
      </div>
      <!-- <img class="product-image" src="@/assets/product/1/1.png" alt=""> -->
    </div>
    <div class="technology-introduce">
      <div class="wrapper">
        <el-row :gutter="60">
          <el-col :span="12">
            <div class="f40 title fbold c333">技术介绍</div>
            <div class="f18 c666 desc">
              聚焦数据采集、交换、处理、存储、分析、挖掘、管控和应用等数据处理及数据应用全环节，为不同行业用户提供全方位的数据解决方案，构建数据资产，实现数据价值变现且持续增值，促进业务流程再造和业务模式升级。
            </div>
          </el-col>
          <el-col :span="12">
            <img class="r-img s-r-img" src="@/assets/technology/2/2.png" alt="">
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="advantages">
      <p class="title">技术优势</p>
      <div class="wrapper">
        <el-row :gutter="40">
          <el-col :span="6">
            <el-card shadow="always">
              <img src="@/assets/technology/2/3.png" alt="">
              <p class="s-title">先进性</p>
              <p class="s-desc">采用最新的AI和机器学习的技术，实现数据标准和元数据的自动匹配，轻松实现智能的数据资产目录。</p>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card shadow="always">
              <img src="@/assets/technology/2/4.png" alt="">
              <p class="s-title">可信性</p>
              <p class="s-desc">多种内置的数据质量分析规则，结合自助式的数据质量规则的制定，轻松迅速的了解数据质量并改善数据质量。</p>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card shadow="always">
              <img src="@/assets/technology/2/5.png" alt="">
              <p class="s-title">方便性</p>
              <p class="s-desc">能够自动发现数据，分析数据质量。AI技术赋能最大程度上提供了数据自动治理，减少人工劳动。</p>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card shadow="always">
              <img src="@/assets/technology/2/6.png" alt="">
              <p class="s-title">安全性</p>
              <p class="s-desc">灵活设置数据权限，全方位的保障数据安全。统一访问控制和权限体系，有效保障业务系统安全。 </p>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="application">
      <p class="title">技术应用</p>
      <div class="wrapper">
        <el-card shadow="always">
          <div class="content">
            <img class="l-img" src="@/assets/technology/2/7.png" alt="">
            <div class="r-desc">
              <p class="f18 t1">SOM智能运维平台</p>
              <p class="f16 t2">
                提供全面的网络设备性能监控，是具备监控、管理、分析和预测预警功能的新一代运维解决方案，实时保障业务安全稳定的运行。</p>
              <div class="more" @click="goTo('/product/1')">
                查看详情 >
              </div>
            </div>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import platformItem from "@/components/platformItem.vue";
export default {
  data() {
    return {
      activeName: "first"
    };
  },
  created() {},
  methods: {
    goTo(url) {
      this.$router.push(`${url}`);
    }
  },
  components: {
    platformItem
  }
};
</script>

<style scoped lang="less">
</style>
