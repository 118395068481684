<template>
  <div class="iriets-technology">
    <div class="top-banner">
      <div class="technology-image" :style="{'backgroundImage':`url(${require('@/assets/technology/3/1.png')})`}">
        <div class="wrapper">
          <el-row>
            <el-col :span="12">
              <div class="f50 cfff title">智慧运维</div>
              <div class="f20 cfff desc">
                以算法为支撑，数据为基础，场景为导向，将收集的海量数据进行集成、治理、多维分析，充分挖掘数据潜力，助力企业高效运行、快速决策。
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="technology-introduce">
      <div class="wrapper">
        <p class="title c333">技术介绍</p>
        <p class="desc c666">
          突破基于大数据的故障检测技术，利用云计算、人工智能、组态化等技术搭建设备资产运维管理平台，解决了当前行业故障发现难、运维监管难等问题，实现了设备的快速接入、故障精准检测、资产全生命周期管理以及运维的全流程可视化闭环管理。
        </p>
        <el-row :gutter="60" class="need-border">
          <el-col :span="8" class="text-center">
            <img src="@/assets/technology/3/2.png" alt="">
            <span class="s-title">组织人员</span>
            <span class="s-desc c666">专业的技术人员，全球化的运维管理经验，为客户 打造可靠信赖的运维团队。</span>
          </el-col>
          <el-col :span="8" class="text-center">
            <img src="@/assets/technology/3/3.png" alt="">
            <span class="s-title">运维流程</span>
            <span class="s-desc c666">引入ITIL标准，规范运维流程 ，需求快速相应， 提升运维效率。</span>
          </el-col>
          <el-col :span="8" class="text-center">
            <img src="@/assets/technology/3/4.png" alt="">
            <span class="s-title">运维平台</span>
            <span class="s-desc c666">统一运维平台带来一体化全方位的可视、智能 运维新体验。</span>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="advantages">
      <p class="title">技术优势</p>
      <div class="wrapper">
        <el-row :gutter="40">
          <el-col :span="6">
            <el-card shadow="always">
              <img src="@/assets/technology/3/5.png" alt="">
              <p class="s-title">降低设备运维成本</p>
              <p class="s-desc">提供方便快捷的设备运维管理能力, 帮助售后人员在海量设备中快速检索到指定⽤户以及设备的情况, 实时了解运行状态。</p>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card shadow="always">
              <img src="@/assets/technology/3/6.png" alt="">
              <p class="s-title">产品使用数据分析</p>
              <p class="s-desc">远程查看设备状态, 融合大数据及 AI 能⼒, 提供多种 App 及设备使用的健康度数据情况, 为改进智能设备的用户体验提供数据支持。</p>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card shadow="always">
              <img src="@/assets/technology/3/7.png" alt="">
              <p class="s-title">提⾼售后服务效率</p>
              <p class="s-desc">远程查看家庭成员关系/设备拓扑结构/⽇志等数据, 提供远程调试、远程升级设备等能力, 轻松实现设备故障排查。</p>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card shadow="always">
              <img src="@/assets/technology/3/8.png" alt="">
              <p class="s-title">提升企业品牌价值</p>
              <p class="s-desc">强大的售后支撑能力，提升智能产品在终端用户中的使用体验，及时解决硬件潜在风险，提高企业品牌价值。</p>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="application">
      <p class="title">技术应用</p>
      <div class="wrapper">
        <el-row :gutter="80">
          <el-col :span="12">
            <div class="jishu-yingyong">
              <img class="l-img" src="@/assets/technology/3/9.png" alt="">
              <div class="r-desc">
                <p class="f18 t1">DICT集中运维平台</p>
                <p class="f16 t2">
                  DICT集成了设备监控、自动化运维、日志分析、业务告警等多种功能，推动了运维工作从无序到有序、从被动到主动、从人工到智能的演进。</p>
                <div class="more" @click="goTo('/product/2')">
                  查看详情 >
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="jishu-yingyong">
              <img class="l-img" src="@/assets/technology/3/10.png" alt="">
              <div class="r-desc">
                <p class="f18 t1">SOM智能运维平台</p>
                <p class="f16 t2">
                  提供全面的网络设备性能监控，是具备监控、管理、分析和预测预警功能的新一代运维解决方案，实时保障业务安全稳定的运行。</p>
                <div class="more" @click="goTo('/product/1')">
                  查看详情 >
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import platformItem from "@/components/platformItem.vue";
export default {
  data() {
    return {
      activeName: "first"
    };
  },
  created() {},
  methods: {
    goTo(url) {
      this.$router.push(`${url}`);
    }
  },
  components: {
    platformItem
  }
};
</script>

<style scoped lang="less">
</style>
