<template>
  <div class="iriets-technology">
    <div class="top-banner">
      <div class="technology-image" :style="{'backgroundImage':`url(${require('@/assets/technology/4/1.png')})`}">
        <div class="wrapper">
          <el-row>
            <el-col :span="12">
              <div class="f50 cfff title">SaaS平台</div>
              <div class="f20 cfff desc">
                深耕市场，在充分了解市场需求以及用户心理的情况下，为客户提供最全面，最完善的SaaS服务。助企业降低IT成本，让企业投入更有价值。
              </div>
            </el-col>
          </el-row>

        </div>
      </div>
      <!-- <img class="product-image" src="@/assets/product/1/1.png" alt=""> -->
    </div>
    <div class="technology-introduce">
      <div class="wrapper">
        <el-row :gutter="60">
          <el-col :span="12">
            <div class="f40 title fbold c333">服务描述</div>
            <div class="f18 c666 desc">
              从市场需求、用户痛点、行业分析等几大方面综合分析，为企业搭建信息化所需要的物联网基础设施及软件运作平台，并负责所有前期的实施、后期的维护等服务，系统和模块灵活选购配置，为不同性质的企业提供各类saas服务。
            </div>
          </el-col>
          <el-col :span="12">
            <img class="r-img" src="@/assets/technology/4/2.png" alt="">
          </el-col>
        </el-row>

      </div>
    </div>
    <div class="advantages">
      <p class="title">我们的优势</p>
      <div class="wrapper">
        <el-row :gutter="40">
          <el-col :span="6">
            <el-card shadow="always">
              <img src="@/assets/technology/4/3.png" alt="">
              <p class="s-title">方案精</p>
              <p class="s-desc">优质品牌各业务环节的精细化方案，满足企业的不同需求。</p>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card shadow="always">
              <img src="@/assets/technology/4/4.png" alt="">
              <p class="s-title">迭代快</p>
              <p class="s-desc">互联网SaaS架构模式，每年按需迭代发版，保障功能快速实现和迭代。</p>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card shadow="always">
              <img src="@/assets/technology/4/5.png" alt="">
              <p class="s-title">服务好</p>
              <p class="s-desc">一切以服务好客户为原则全程跟进，多位专业人员为服务保障。</p>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card shadow="always">
              <img src="@/assets/technology/4/6.png" alt="">
              <p class="s-title">超链接</p>
              <p class="s-desc">打通多家第三方系统（OMS/ ERP/ES/RFID等），保障数据的高效协同。</p>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="application">
      <p class="title">技术应用</p>
      <div class="wrapper">
        <el-row :gutter="80">
          <el-col :span="12">
            <div class="jishu-yingyong">
              <img class="l-img" src="@/assets/technology/4/7.png" alt="">
              <div class="r-desc">
                <p class="f18 t1">SaaS电商平台</p>
                <p class="f16 t2">
                  帮助品牌快速搭建自有渠道电商平台，提供多样的促销活动，丰富可配的活动模版，多终端的展现方式，为品牌快速开展自有电商运营业务赋能。</p>
                <div class="more" @click="goTo('/product/7')">
                  查看详情 >
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="jishu-yingyong">
              <img class="l-img" src="@/assets/technology/4/8.png" alt="">
              <div class="r-desc">
                <p class="f18 t1">智能审核平台</p>
                <p class="f16 t2">
                  以文本、图片、视频、音频识别技术为核心，为各业务团队提供全方位的审核服务，实现“人工+智能”的审核目标，提前防御内容风险，提升用户体验。</p>
                <div class="more" @click="goTo('/product/3')">
                  查看详情 >
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import platformItem from "@/components/platformItem.vue";
export default {
  data() {
    return {
      activeName: "first"
    };
  },
  created() {},
  methods: {
    goTo(url) {
      this.$router.push(`${url}`);
    }
  },
  components: {
    platformItem
  }
};
</script>

<style scoped lang="less">
</style>
