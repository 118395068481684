<template>
  <div class="iriets-technology">
    <technology-number v-if="id==1"></technology-number>
    <technology-data v-if="id==2"></technology-data>
    <technology-ai v-if="id==3"></technology-ai>
    <technology-saas v-if="id==4"></technology-saas>
  </div>
</template>

<script>
import technologyNumber from "./page/technology-number.vue";
import technologyData from "./page/technology-data.vue";
import technologyAi from "./page/technology-ai.vue";
import technologySaas from "./page/technology-saas.vue";
export default {
  data() {
    return {
      id: null
    };
  },
  created() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
    }
    console.log(this.id);
  },
  methods: {},
  watch: {
    $route() {
      this.id = this.$route.params.id;
      console.log(this.$route.params.id);
    }
  },
  components: {
    technologyNumber,
    technologyData,
    technologyAi,
    technologySaas
  }
};
</script>

<style scoped lang="less">
</style>
