<template>
  <div class="iriets-technology">
    <div class="top-banner">
      <div class="technology-image" :style="{'backgroundImage':`url(${require('@/assets/technology/1/1.png')})`}">
        <div class="wrapper">
          <el-row>
            <el-col :span="12">
              <div class="f50 cfff title">数字孪生</div>
              <div class="f20 cfff desc">
                以数字孪生为理念，三维可视化为特点，深度融合IOT物联网、5G、大数据、云计算、AI、融合通信等前沿技术应用，将信息、技术、设备与业务需求有机结合形成各类管理场景，为各类数据提供承载平台。
              </div>
            </el-col>
          </el-row>

        </div>
      </div>
      <!-- <img class="product-image" src="@/assets/product/1/1.png" alt=""> -->
    </div>
    <div class="technology-introduce">
      <div class="wrapper">
        <el-row :gutter="60">
          <el-col :span="12">
            <div class="f40 title fbold c333">技术介绍</div>
            <div class="f18 c666 desc">
              通过建立企业实体的数字模型，利用各种传感器、历史数据和物理模型等，模拟企业的实际运营过程，从而帮助企业更好地理解自身的运营状态和性能，进行更有效的优化和管理。数字孪生技术可以帮助企业实现运营过程的可视化、可预测和可优化，进而提升企业的生产效益、降低成本、改善质量并提高服务水平。
            </div>
          </el-col>
          <el-col :span="12">
            <img class="r-img" src="@/assets/technology/1/10.png" alt="">
          </el-col>
        </el-row>

      </div>
    </div>
    <div class="advantages">
      <p class="title">技术优势</p>
      <div class="wrapper">
        <el-row :gutter="40">
          <el-col :span="6">
            <el-card shadow="always">
              <img src="@/assets/technology/1/2.png" alt="">
              <p class="s-title">多样性</p>
              <p class="s-desc">支持矢量地图、影像地图、实景三维模型、仿真三维模型、BIM模型数据加载及展示。</p>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card shadow="always">
              <img src="@/assets/technology/1/3.png" alt="">
              <p class="s-title">实时性</p>
              <p class="s-desc">数字孪生技术以计算机可识别和处理的方式管理数据，对外观、状态、属性、内在机理，形成物理实体实时状态的数。字虚体映射。</p>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card shadow="always">
              <img src="@/assets/technology/1/4.png" alt="">
              <p class="s-title">开放性</p>
              <p class="s-desc">提供丰富的API接口，融合多数据源数据、视频监控数据、传感器数据等，无缝对接各业务平台。</p>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card shadow="always">
              <img src="@/assets/technology/1/5.png" alt="">
              <p class="s-title">可扩展性</p>
              <p class="s-desc">具备集成、添加和替换数字模型的能力，能够针对多尺度、多物理、多层级的模型内容进行扩展。</p>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="application">
      <p class="title">应用场景</p>
      <div class="wrapper">
        <el-row :gutter="40">
          <el-col :span="8">
            <el-card shadow="always">
              <img src="@/assets/technology/1/11.png" alt="">
              <p class="s-title f20 text-center">通讯机房</p>
              <p class="s-desc f16">动态显示设备的三维模型和多维度运行数据，使设备呈现仿真和诊断分析效果。数字通信机房对光纤、电源和空调线路进行从建筑到楼层房间、再到设备构件级的精细化建模和上下游连接关系进行管理，为运维人员检修和故障排查提供高效仿真平台。</p>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card shadow="always">
              <img src="@/assets/technology/1/12.png" alt="">
              <p class="s-title f20 text-center">智慧工厂</p>
              <p class="s-desc f16">全厂信息概览从管理决策层关心的人、财、物、安全角度综合展示场站总体人员概况、生产概况、设备运行概况、安全概况等数据，这些数据主要来自综合业务管理和统计分析结果，结合高风险提示信息，有助于决策层快速了解整个厂区的概况，做出及时有效的决策。 </p>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card shadow="always">
              <img src="@/assets/technology/1/13.png" alt="">
              <p class="s-title f20 text-center">数字化配电房</p>
              <p class="s-desc f16">通过数字孪生技术将物理设备的各种属性映射到虚拟空间中，极大的加速了操作人员对物理实体的了解，可以让很多原来由于物理条件限制、必须依赖于真实的物理实体而无法完成的操作成为触手可及的工具，形成创新应用平台。</p>
            </el-card>
          </el-col>
          <!-- <el-col :span="6">
            <el-card shadow="always">
              <img src="@/assets/technology/1/9.png" alt="">
              <p class="s-title f20 text-center">智慧校园</p>
              <p class="s-desc f16">以数字孪生理念还原学校整体园区及周边环境，为各类数据提供承载平台是一个面向校园辅助校园管理的平台，将校园运行核心系统的各项关键数据进行综合展现。</p>
            </el-card>
          </el-col> -->
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import platformItem from "@/components/platformItem.vue";
export default {
  data() {
    return {
      activeName: "first"
    };
  },
  created() {},
  methods: {},
  components: {
    platformItem
  }
};
</script>

<style scoped lang="less">
</style>
